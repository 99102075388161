export const SET_INVESTOR_TOKENS = (state, payload) => {
  state.userTokens = payload;
};

export const SET_UNLIMITED_INVESTOR_TOKENS = (state, payload) => {
  state.unlimitedUserTokens = payload;
};

export const SET_USER_DEAL_ROOM = (state, payload) => {
  state.userDealRooms = payload;
};

export const SET_USER_PRIVATE_TOKENS = (state, payload) => {
  state.privateTokens = payload;
};
export const SET_RAISING_TOKENS = (state, payload) => {
  state.raisingTokens = payload;
};
export const SET_RAISED_TOKENS = (state, payload) => {
  state.raisedTokens = payload;
};

export const SET_ORDER_BOOK = (state, payload) => {
  state.orderBook = payload;
};

export const SET_ORDER_PAGE = (state, payload) => {
  state.orderBookPage = payload;
};

export const SET_ORDER_PAGES = (state, payload) => {
  state.orderBookPages = payload;
};

export const SET_PUBLIC_TOKENS = (state, payload) => {
  state.publicTokens = payload;
};

export const SET_TOKEN_INFO = (state, payload) => {
  state.tokenInfo = payload;
};

export const SET_ASSET_INFO = (state, payload) => {
  state.assetInfo = payload;
};

export const SET_RAISING_PAGES = (state, payload) => {
  state.raisingPages = payload;
};

export const SET_RAISING_PAGE = (state, payload) => {
  state.raisingPage = payload;
};

export const SET_RAISED_PAGES = (state, payload) => {
  state.raisedPages = payload;
};

export const SET_RAISED_PAGE = (state, payload) => {
  state.raisedPage = payload;
};

export const SET_INVESTOR_TOKEN_PAGES = (state, payload) => {
  state.investorTokenPages = payload;
};

export const SET_INVESTOR_TOKEN_PAGE = (state, payload) => {
  state.investorTokenPage = payload;
};

export const SET_TOKEN_BALANCE = (state, payload) => {
  state.tokenBalance = payload;
};

export const SET_TOKEN_PAYOUT_DETAILS = (state, payload) => {
  state.tokenPayoutDetails = payload;
};

export const SET_DEAL_ROOM_INFO = (state, payload) => {
  state.dealRoomInfo = payload;
};

export const SET_TOKEN_UPDATE = (state, payload) => {
  state.tokenUpdates = payload;
};

export const SET_TOKEN_UPDATE_PAGE = (state, payload) => {
  state.tokenUpdatePage = payload;
};

export const SET_TOKEN_UPDATE_PAGES = (state, payload) => {
  state.tokenUpdatePages = payload;
};

export const SET_DEAL_SUBSCRIPTION_STATUS = (state, payload) => {
  state.subscriptionStatus = payload;
};

export const SET_SUBSCRIPTION_PLANS = (state, payload) => {
  state.subscriptionPlans = payload;
};

export const SET_BUY_ORDER_BOOK = (state, payload) => {
  state.buyOrderBook = payload;
};

export const SET_SELL_ORDER_BOOK = (state, payload) => {
  state.sellOrderBook = payload;
};
export const SET_ORG_INFO = (state, payload) => {
  state.orgInfo = payload;
};
export const SET_WHY_WE_LISTED = (state, payload) => {
  state.whyWeListed = payload;
};
export const SET_CERTIFICATE_INFO = (state, payload) => {
  state.certificateInfo = payload;
};
export const SET_BADGE_INFO = (state, payload) => {
  state.badgeInfo = payload;
};
