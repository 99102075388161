export const userTokens = (state) => state.userTokens;
export const userDealRooms = (state) => state.userDealRooms;
export const ongoingTokens = (state) => state.ongoingTokens;
export const completedTokens = (state) => state.completedTokens;
export const privateTokens = (state) => state.privateTokens;
export const orderBook = (state) => state.orderBook;
export const publicTokens = (state) => state.publicTokens;
export const tokenInfo = (state) => state.tokenInfo;
export const assetInfo = (state) => state.assetInfo;
export const pages = (state) => state.pages;
export const page = (state) => state.page;
export const tokenBalance = (state) => state.tokenBalance;
export const tokenPayoutDetails = (state) => state.tokenPayoutDetails;
export const dealRoomInfo = (state) => state.dealRoomInfo;
export const tokenUpdates = (state) => state.tokenUpdates;
export const subscriptionStatus = (state) => state.subscriptionStatus;
export const subscriptionPlans = (state) => state.subscriptionPlans;
export const orgInfo = (state) => state.orgInfo;
export const companiesRaising = (state) => state.raisingTokens;
export const raisingPage = (state) => state.raisingPage;
export const raisingPages = (state) => state.raisingPages;
export const certificateInfo = (state) => state.certificateInfo;
export const badgeInfo = (state) => state.badgeInfo;